<template>
  <base-section
    id="trading-gloves"
    class="px-5"
  >
    <base-section-heading
      title="TRADING OF ALL TYPES OF NITRILE AND NATURAL RUBBER GLOVES"
    >
    </base-section-heading>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="3"
          sm="12"
        >
          <v-card
            class="mx-auto black--text"
            :min-height="$vuetify.breakpoint.mdAndUp ? 330 : 220"
          >
            <v-img
              contain
              :src="info.src"
              :height="$vuetify.breakpoint.mdAndUp ? 200 : 100"
            ></v-img>

            <v-card-title>
              {{info.title}}
            </v-card-title>
            <v-card-text
              class="black--text v-space_control"
            >
              {{info.text}}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTradingGloves',
    data () {
      return {
        information: [
          {
            title: 'Sourcing',
            text: 'Sourcing for quality gloves with competitive price as per customer requirements',
            src: require('@/assets/sourcing.png'),
          },
          {
            title: 'Packing',
            text: 'Supporting OEM brand packing.',
            src: require('@/assets/packing.png'),
          },
          {
            title: 'Quality Assurance',
            text: 'Ensure glove quality is meeting international standards and regulatory compliance.',
            src: require('@/assets/quality.png'),
          },
          {
            title: 'Shipment',
            text: 'On time delivery to customer',
            src: require('@/assets/shipment.png'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
